<template>
  <div class="fixed top-0 right-0 left-0 z-20">
    <header class="w-full border-t-4 border-yellow-400 shadow-lg">
      <div class="border-b-2 border-neutral-900 bg-black">
        <div
          class="mx-auto w-full max-w-[var(--breakpoint-2xl)] px-6 py-4 sm:px-8"
        >
          <div class="flex items-center">
            <NuxtLink to="/corporates" class="shrink-0">
              <NuxtImg
                provider="storyblok"
                class="h-16 w-auto"
                height="64"
                width="74"
                :src="story.content.site_logo.filename"
                :alt="story.content.site_logo.alt"
                preload
                priority
                loading="eager"
              />
            </NuxtLink>
            <UNavigationMenu
              v-model="active"
              :items="menu"
              variant="link"
              :ui="{
                link: 'text-md font-semibold px-3.5 py-2.5',
                viewport: 'bg-neutral-900 max-w-[var(--breakpoint-md)]',
              }"
              class="mx-auto hidden justify-center lg:flex"
            />
            <div
              class="ml-auto flex shrink-0 items-center justify-center space-x-2 lg:ml-0"
            >
              <div class="lg:hidden">
                <USlideover
                  v-model:open="mobileOpen"
                  title="Live For Today Corporates"
                  :close="{
                    color: 'primary',
                    variant: 'outline',
                    class: 'rounded-full bg-black z-[1]',
                  }"
                  :ui="{
                    content: 'divide-y-0 bg-neutral-950/90 backdrop-blur-3xl',
                  }"
                >
                  <UButton
                    title="Menu"
                    size="xl"
                    variant="subtle"
                    icon="i-lucide-menu"
                    class="cursor-pointer"
                  />
                  <template #body>
                    <UNavigationMenu
                      v-model="active"
                      :items="menu"
                      orientation="vertical"
                      :ui="{
                        link: 'text-md font-semibold px-3.5 py-2.5',
                      }"
                    />
                  </template>
                  <template #footer>
                    <UButton
                      variant="subtle"
                      class="w-full cursor-pointer justify-center"
                      to="/"
                      icon="i-lucide-undo-dot"
                    >
                      Back To Main Site
                    </UButton>
                  </template>
                </USlideover>
              </div>
              <div class="hidden lg:block">
                <UButton
                  variant="subtle"
                  class="cursor-pointer"
                  to="/"
                  icon="i-lucide-undo-dot"
                >
                  Back To Main Site
                </UButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script setup lang="ts">
const isPreview = useRuntimeConfig().public.NODE_ENV !== 'production';
const mobileOpen = ref(false);

const story = await useAsyncStoryblok(
  'config',
  {
    version: isPreview ? 'draft' : 'published',
    // resolve_relations: 'overview.featured_story',
  }
  // { resolveRelations: 'overview.featured_story' }
);

const active = ref();

const filteredMinisite = (story.value.content.minisites || []).find(
  (item) => item.brand_name === 'corporates'
);

const menu = ref(filteredMinisite ? filteredMinisite.menu : []);

// Format Navigation menu Items
function processMenuData(menuData) {
  return menuData.map((item) => {
    const newItem = {};

    newItem.label = item.label;
    newItem.icon = item.icon;

    if (item.to) {
      let url = '';
      if (item.to.linktype === 'story') {
        url = `/${item.to.cached_url}`;
      } else {
        url = item.to.url;
      }
      // Remove trailing slashes
      url = url.replace(/\/+$/, '');
      newItem.to = url;
    }

    if (item.description) {
      newItem.description = item.description;
    }

    if (item.children && item.children.length > 0) {
      newItem.children = processMenuData(item.children);
    }

    return newItem;
  });
}

if (menu.value.length > 0) {
  menu.value = processMenuData(menu.value);
}

const route = useRoute();

// Watch for route changes to close the mobile menu
watch(
  () => route.path,
  () => {
    mobileOpen.value = false;
  }
);
</script>
